export const workouts = [
  {
    id: 0,
    username: "_.crysty",
    flag: "🇪🇸",
    city: "Spain",
    inst: "https://www.instagram.com/_.crysty",
    tik: "https://www.tiktok.com/@_.crysty",
    type: "Full Body",
    description: "10 rounds for time :\n200 m run\n5 t2b\n10 burpee target*\n15 box jump\n20 wb*\n30s rest between rounds\n*-1 burpee + 1 wb each round",
    time: "37 minutes"
  },
  {
    id: 1,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Cardio",
    description: "AMRAP in 40 minutes:\n400m Run\n15 Burpees\n15 Box Jumps (24/20 inches)",
    time: "40 minutes"
  },
  {
    id: 2,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Upper Body",
    description: "Every 2 minutes for 30 minutes:\n5 Push Press (135/95 lbs)\n10 Dips\n15 Push-ups",
    time: "30 minutes"
  },
  {
    id: 3,
    username: "WOD_SDiego",
    flag: "🇺🇸",
    city: "San Diego",
    inst: "http//:www.insttagram.com/WOD_SDiego",
    type: "Lower Body",
    description: "6 rounds for time:\n400m Run\n15 Front Squats (135/95 lbs)\n15 Jumping Lunges (per leg)",
    time: "35-40 minutes"
  },
  {
    id: 4,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Core",
    description: "AMRAP in 35 minutes:\n20 GHD Sit-ups\n20 Toes-to-Bar\n20 Russian Twists (20/15 lbs)",
    time: "35 minutes"
  },
  {
    id: 5,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "For time:\n100 Deadlifts (135/95 lbs)\n100 Push-ups\n100 Box Jumps (24/20 inches)\n100 Wall Ball Shots (20/14 lbs)\n100 Burpees",
    time: "40-45 minutes"
  },
  {
    id: 6,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Cardio",
    description: "5 rounds for time:\n800m Run\n30 Kettlebell Swings (53/35 lbs)\n30 Air Squats",
    time: "35-40 minutes"
  },
  {
    id: 7,
    username: "WOD_SDiego",
    flag: "🇺🇸",
    city: "San Diego",
    inst: "http//:www.insttagram.com/WOD_SDiego",
    type: "Upper Body",
    description: "Every minute on the minute for 30 minutes:\nOdd minutes: 10 Dumbbell Snatches (50/35 lbs)\nEven minutes: 10 Handstand Push-ups",
    time: "30 minutes"
  },
  {
    id: 8,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Lower Body",
    description: "5 rounds for time:\n400m Run\n15 Power Cleans (135/95 lbs)\n15 Front Squats (135/95 lbs)",
    time: "30-35 minutes"
  },
  {
    id: 9,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Core",
    description: "AMRAP in 40 minutes:\n15 Toes-to-Bar\n30 Double-Unders\n15 Medicine Ball Cleans (20/14 lbs)",
    time: "40 minutes"
  },
  {
    id: 10,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "For time:\n50-40-30-20-10 reps of:\nWall Ball Shots (20/14 lbs)\nKettlebell Swings (53/35 lbs)\nCalorie Row",
    time: "35-40 minutes"
  },
  {
    id: 11,
    username: "WOD_SDiego",
    flag: "🇺🇸",
    city: "San Diego",
    inst: "http//:www.insttagram.com/WOD_SDiego",
    type: "Cardio",
    description: "Every 5 minutes for 40 minutes:\n400m Run\n15 Burpees\nMax rep Pull-ups",
    time: "40 minutes"
  },
  {
    id: 12,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Upper Body",
    description: "5 rounds for time:\n15 Dumbbell Push Press (50/35 lbs)\n15 Pull-ups\n15 Dips",
    time: "30-35 minutes"
  },
  {
    id: 13,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Lower Body",
    description: "AMRAP in 35 minutes:\n10 Deadlifts (225/155 lbs)\n20 Walking Lunges\n30 Box Jumps (24/20 inches)",
    time: "35 minutes"
  },
  {
    id: 14,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Core",
    description: "5 rounds for time:\n20 GHD Sit-ups\n20 V-ups\n20 Russian Twists (20/15 lbs)\n20 Hollow Rocks",
    time: "30-35 minutes"
  },
  {
    id: 15,
    username: "WOD_SDiego",
    flag: "🇺🇸",
    city: "San Diego",
    inst: "http//:www.insttagram.com/WOD_SDiego",
    type: "Full Body",
    description: "For time:\n100 Thrusters (95/65 lbs)\n100 Chest-to-Bar Pull-ups\n100 Burpees",
    time: "40-45 minutes"
  },
  {
    id: 16,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "5 rounds for time:\n20 Thrusters (95/65 lbs)\n20 Pull-ups\n400m Run",
    time: "30-35 minutes"
  },
  {
    id: 17,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "AMRAP in 40 minutes:\n5 Deadlifts (225/155 lbs)\n10 Burpees\n15 Box Jumps (24/20 in)",
    time: "40 minutes"
  },
  {
    id: 18,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "For time:\n100 Wall Balls (20/14 lbs)\n80 Kettlebell Swings (53/35 lbs)\n60 Toes-to-Bar\n40 Calorie Row\n20 Handstand Push-ups",
    time: "35-40 minutes"
  },
  {
    id: 19,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "21-15-9 reps for time:\nClean and Jerks (135/95 lbs)\nRing Dips\nDouble-Unders (63-45-27)",
    time: "30-35 minutes"
  },
  {
    id: 20,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "Every 3 minutes for 30 minutes:\n10 Power Cleans (135/95 lbs)\n15 Push-ups\n20 Air Squats",
    time: "30 minutes"
  },
  {
    id: 21,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "5 rounds for time:\n15 Dumbbell Snatches (50/35 lbs, alternating)\n20 GHD Sit-ups\n25 Double-Unders",
    time: "35-40 minutes"
  },
  {
    id: 22,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "For time:\n50-40-30-20-10 reps of:\nWall Balls (20/14 lbs)\nCalorie Assault Bike\n+\n5 Rope Climbs after each round",
    time: "40-45 minutes"
  },
  {
    id: 23,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "AMRAP in 35 minutes:\n7 Power Snatches (135/95 lbs)\n14 Chest-to-Bar Pull-ups\n21 Walking Lunges",
    time: "35 minutes"
  },
  {
    id: 24,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "4 rounds for time:\n400m Run\n15 Overhead Squats (95/65 lbs)\n30 Russian Twists (20/14 lb plate)",
    time: "30-35 minutes"
  },
  {
    id: 25,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "Every 5 minutes for 40 minutes:\n10 Hang Power Cleans (135/95 lbs)\n15 Box Jump Overs (24/20 in)\n20 Calorie Row",
    time: "40 minutes"
  },
  {
    id: 26,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "For time:\n50 Thrusters (75/55 lbs)\n50 Pull-ups\n50 Burpees\n50 Kettlebell Swings (53/35 lbs)\n50 Calorie Assault Bike",
    time: "35-40 minutes"
  },
  {
    id: 27,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "AMRAP in 30 minutes:\n5 Muscle-ups\n10 Alternating Pistols\n15 Dumbbell Renegade Rows (2x50/35 lbs)",
    time: "30 minutes"
  },
  {
    id: 28,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "5 rounds for time:\n15 Deadlifts (225/155 lbs)\n20 Toes-to-Bar\n25 Wall Balls (20/14 lbs)\n400m Run",
    time: "40-45 minutes"
  },
  {
    id: 29,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "Every 4 minutes for 36 minutes:\n12 Power Cleans (115/75 lbs)\n12 Front Squats (115/75 lbs)\n12 Push Jerks (115/75 lbs)",
    time: "36 minutes"
  },
  {
    id: 30,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "For time:\n100 Double-Unders\n50 Burpee Box Jump-Overs (24/20 in)\n40 Hang Power Cleans (135/95 lbs)\n30 Strict Handstand Push-ups\n20 Bar Muscle-ups",
    time: "35-40 minutes"
  },
  {
    id: 31,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "AMRAP in 40 minutes:\n5 Rope Climbs\n10 Bench Press (bodyweight)\n15 Box Jumps (30/24 in)\n20 Calorie Ski Erg",
    time: "40 minutes"
  },
  {
    id: 32,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "21-15-9 reps for time:\nSumo Deadlift High Pulls (95/65 lbs)\nBurpees Over the Bar\nRing Dips\n+\n400m Run after each round",
    time: "30-35 minutes"
  },
  {
    id: 33,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "Every 3 minutes for 33 minutes:\n10 Power Snatches (95/65 lbs)\n15 Pull-ups\n20 Alternating Lunges (10 each leg)",
    time: "33 minutes"
  },
  {
    id: 34,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "For time:\n50-40-30-20-10 reps of:\nWall Balls (20/14 lbs)\nKettlebell Swings (53/35 lbs)\n+\n10 Burpees after each round",
    time: "35-40 minutes"
  },
  {
    id: 35,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "AMRAP in 45 minutes:\n7 Thrusters (135/95 lbs)\n7 Chest-to-Bar Pull-ups\n7 Bar-Facing Burpees",
    time: "45 minutes"
  },
  {
    id: 36,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "5 rounds for time:\n15 Dumbbell Clean and Jerks (50/35 lbs)\n20 GHD Sit-ups\n25 Calorie Assault Bike\n30 Double-Unders",
    time: "40-45 minutes"
  },
  {
    id: 37,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "Every 5 minutes for 35 minutes:\n10 Deadlifts (225/155 lbs)\n15 Toes-to-Bar\n20 Box Jump Overs (24/20 in)",
    time: "35 minutes"
  },
  {
    id: 38,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "For time:\n100 Calorie Row\n80 Wall Balls (20/14 lbs)\n60 Devil's Press (2x50/35 lbs dumbbells)\n40 Pull-ups\n20 Thrusters (135/95 lbs)",
    time: "35-40 minutes"
  },
  {
    id: 39,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "AMRAP in 40 minutes:\n5 Strict Muscle-ups\n10 Single-Arm Dumbbell Snatches (50/35 lbs, alternating)\n15 Pistols (alternating)\n20 Calorie Ski Erg",
    time: "40 minutes"
  },
  {
    id: 40,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "21-15-9 reps for time:\nPower Cleans (135/95 lbs)\nRing Dips\nBox Jumps (30/24 in)\n+\n400m Run after each round",
    time: "30-35 minutes"
  },
  {
    id: 41,
    username: "BCN_wod",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "http//:www.insttagram.com/BCN_wod",
    type: "Full Body",
    description: "Every 4 minutes for 32 minutes:\n8 Hang Power Snatches (95/65 lbs)\n12 Burpee Box Jump-Overs (24/20 in)\n16 Russian Kettlebell Swings (53/35 lbs)",
    time: "32 minutes"
  },
  {
    id: 42,
    username: "capo_wod",
    flag: "🇧🇷",
    city: "Sao Paulo",
    inst: "http//:www.insttagram.com/capo_wod",
    type: "Full Body",
    description: "For time:\n50 Thrusters (75/55 lbs)\n40 Pull-ups\n30 Dumbbell Snatches (50/35 lbs)\n20 Handstand Push-ups\n10 Muscle-ups",
    time: "35-40 minutes"
  },
  {
    id: 43,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "AMRAP in 45 minutes:\n10 Power Cleans (135/95 lbs)\n20 Push-ups\n30 Air Squats\n40 Double-Unders",
    time: "45 minutes"
  },
  {
    id: 44,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "5 rounds for time:\n400m Run\n15 Dumbbell Thrusters (2x50/35 lbs)\n20 Pull-ups\n25 Sit-ups",
    time: "35-40 minutes"
  },
  {
    id: 45,
    username: "royal_fit",
    flag: "🇬🇧",
    city: "London",
    inst: "http//:www.insttagram.com/royal_fit",
    type: "Full Body",
    description: "Every 3 minutes for 30 minutes:\n5 Deadlifts (275/185 lbs)\n10 Bar Muscle-ups\n15 Wall Balls (20/14 lbs)",
    time: "30 minutes"
  },
  {
    id: 46,
    username: "_.crysty",
    flag: "🇪🇸",
    city: "Spain",
    inst: "https://www.instagram.com/_.crysty",
    tik: "https://www.tiktok.com/@_.crysty",
    type: "Cardio",
    description: "E2MO2M x 4 Rondas:\n25/20 cal row\n100 du\n400m Run\n25 Heavy wall ball",
    time: "32 minutes"
  }
];